import { Tag, Text, Tooltip } from "@chakra-ui/react";
import { PromptTemplateDTO } from "../../../../database/entity/PromptTemplate";
import { PromptTemplateConfigType } from "../../../../configuration/types";
import { useSettings } from "../../../../hooks/use-settings";
import { useContext } from "react";
import { LocalizationContext } from "../../../../hooks/use-localization";
interface Props {
  templates: PromptTemplateDTO[];
  type: PromptTemplateConfigType;
}

export default function GenericModalBody(props: Props) {
  const { l } = useContext(LocalizationContext);
  const { settings } = useSettings();

  const onClick = (slug: string) => {
    window.location.href = `/app/chat?t=${slug}`;
  };

  const templates = props.templates
    .filter((t) => t.type === props.type)
    .filter((t) => t.status === "listed")
    .filter((t) => !t.proficiency || t.proficiency === settings.practiceLanguageProficiency)
    // Filter by language: show templates that have no language (all languages) or
    // match the selected language ISO code (checking prefix)
    .filter((t) => {
      // If template has no language specified, it's available for all languages
      if (!t.languageIsoCode) return true;

      // Get the language ISO code (e.g., 'en' from 'en-US')
      const practiceLanguageIso = settings.practiceLanguage.split("-")[0];

      // Check if template language matches the user's practice language ISO code
      return t.languageIsoCode === practiceLanguageIso;
    });

  return (
    <div>
      {templates.length > 0 ? (
        templates.map((item, i) => (
          <Tag
            size={"md"}
            key={i}
            colorScheme={"brand.secondary"}
            m={1}
            onClick={() => onClick(item.slug)}
            _hover={{ opacity: 0.7, cursor: "pointer" }}
          >
            <Tooltip label={item.description} hasArrow placement="top" openDelay={200} mb={2}>
              {item.title}
            </Tooltip>
          </Tag>
        ))
      ) : (
        <Text fontSize="sm" color="brand.blue.500">
          {l["dashboard.template.modal.noContent"]}
        </Text>
      )}
    </div>
  );
}

import {
  ConversationMessageRole,
  ConversationMessage,
  Conversation,
} from "../../../types/conversation";
import { Settings } from "../../../types/settings";
import { fetchTranslation } from "../../fetch-translation";

import { v4 as uuidv4 } from "uuid";
import { PromptTemplateDTO } from "../../../database/entity/PromptTemplate";

export async function getTranslatedMessage(enMessage: string, settings: Settings) {
  if (settings.practiceLanguage === "en-US" || settings.practiceLanguage === "en-GB") {
    return enMessage;
  }

  const text = await fetchTranslation(enMessage, "en-US", settings.practiceLanguage!);

  return text;
}

export function createMessage(
  content: string,
  role: ConversationMessageRole = "system",
): ConversationMessage {
  return {
    role,
    content,
    id: uuidv4(),
  };
}

export function createTagMessageFromPromptTemplate(template: PromptTemplateDTO) {
  return createMessage(getTemplateTagFromPromptTemplate(template));
}

export function getTemplateTagFromPromptTemplate(template: PromptTemplateDTO) {
  return `[prompt-template=${template.slug}]`;
}

export const startConversationTag = "[start-conversation]";
export const freeTalkTag = "[free-talk]";
export const onBoardingTag = "[on-boarding]";
export const lessonTag = "[lesson]";
export const lessonTagWithSlug = (slug: string) => `[lesson=${slug}]`;

export const getVisibleConversation = (conversation: Conversation) => {
  const filteredMessages = conversation.messages.filter((m) => {
    // Filter out system messages
    if (m.role === "system") return false;

    // If message is from user and contains startConversationTag
    if (m.role === "user" && m.content.includes(startConversationTag)) {
      return false;
    }

    // Find index of current message
    const index = conversation.messages.indexOf(m);

    // If this is an assistant message, check if next message is a user message with startConversationTag
    if (m.role === "assistant" && index < conversation.messages.length - 1) {
      const nextMessage = conversation.messages[index + 1];
      if (nextMessage.role === "user" && nextMessage.content.includes(startConversationTag)) {
        return false;
      }
    }

    return true;
  });

  return {
    ...conversation,
    messages: filteredMessages,
  };
};
